<template>
  <div class="earned-points">
    <p v-if="points !== 0">
      {{ $t('scan.youEarned') }} <span class="points-text"> {{ points }} </span> {{ $t('scan.points') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Points',
  components: {},
  data() {
    return {};
  },
  props: {
    points: Number,
    attempted: String,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.earned-points {
  // position: absolute;
  // bottom: 39px;
  // left: 50%;
  // transform: translateX(-50%);
  width: 100%;
  // font-family: Ford F-1;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // height: 4.375em;
  // padding: 10px;
  // background-image: url(../assets/images/bg_nav.png);
  // background-repeat: no-repeat;
  // background-size: cover;
  // box-shadow: 0 2px 5px black;
  color: #222;
  text-align: center;
  // white-space: nowrap;
  // .points-text {
  //   font-weight: bold;
  // }
  p {
    margin-top: -12px;
  }
}
</style>
